/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('ul.products li.product-category').matchHeight();
        if( $(window).width() >= 768 && $('.x-block-grid.two-up').length > 0 ) {
          if( ! $('.x-block-grid.two-up').hasClass('set') ) {
            var parentSection = $('.x-block-grid.two-up').parents('.x-section');
            var height = parentSection.height();
            $('.x-block-grid.two-up > li > .x-promo').parent().addClass('set').css('height', height / 2 +'px');
          }
        }

        $("#navbar-primary").on("show.bs.collapse", function() {
          $('.banner').addClass('dropdown-active');
        });
        $("#navbar-primary").on("hide.bs.collapse", function() {
          $('.banner').removeClass('dropdown-active');
        });

        if( $('body:not(.home) .featured-products-carousel .products').length > 0 ) {
          $('body:not(.home) .featured-products-carousel .products').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          });
        }

        if( $('.x-promo').length > 0 ) {
          $('.x-promo').on('click', function(e) {
            e.preventDefault();
            window.location = $(this).find('a').attr('href');
          });
        }

        var cfHeight = $('#cs-content .x-section.content-float > .x-container').outerHeight();
        $('#cs-content .x-section.content-float > .x-container').css('marginTop', cfHeight / 2 * -1);

        $('#cs-content .x-container.marginless-columns .column-container.x-1-2').wrapInner('<div></div>');

        $.each($('.sidebar .widget'), function() {
          if( $(this).hasClass('woocommerce widget_layered_nav') || $(this).hasClass('woocommerce widget_price_filter') ) {
            var widget_id = $(this).attr('id');
            var widget_title = $(this).find('.widget-title').text();
            var widget_title_html = '<a data-toggle="collapse" href="#collapse-' + widget_id + '" aria-expanded="true" aria-controls="collapse-' + widget_id + '">' + widget_title + '</a>';
            var widget_content = $(this).find('.widget-title').next();

            $(this).find('.widget-title').attr('style', 'padding-top: 0; padding-bottom: 0');

            $(this).find('.widget-title').html(widget_title_html);
            widget_content.wrap('<div class="collapse in" id="collapse-' + widget_id + '"></div>');
          }
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // My Account
    'my_account': {
      init: function() {
        $('.u-columns form').matchHeight();
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'single_product': {
      init: function() {
        $('.product .images .thumbnails a').magnificPopup({
          type: 'image',
          gallery: {
            enabled: true
          }
        });
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $(document).ready(function () {
    // Add the "scrolled" class to the body when the user scrolls
    $(window).scroll(function () {
      if ($(this).scrollTop() > 0) {
        $('.notice').addClass('scrolled');
      } else {
        $('.notice').removeClass('scrolled');
      }
    });
  });

  /*$(document).ready(function($) {
    // Check if the free shipping radio button exists
    var freeShippingRadioButton = $('input[name="shipping_method[0]"][value="advanced_free_shipping"]');
    
    // If the free shipping radio button exists
    if (freeShippingRadioButton.length > 0) {
        // Check if it's not already selected
        if (!freeShippingRadioButton.prop('checked')) {
            // Select the free shipping option
            freeShippingRadioButton.prop('checked', true);
        }
    }
  });*/

})(jQuery); // Fully reference jQuery after this point.
